export const COIN_LIST_REQUEST = 'COIN_LIST_REQUEST'
export const COIN_LIST_SUCCESS = 'COIN_LIST_SUCCESS'
export const COIN_LIST_FAIL = 'COIN_LIST_FAIL'

export const COIN_DETAILS_REQUEST = 'COIN_DETAILS_REQUEST'
export const COIN_DETAILS_SUCCESS = 'COIN_DETAILS_SUCCESS'
export const COIN_DETAILS_FAIL = 'COIN_DETAILS_FAIL'

export const COIN_GAINERS_REQUEST = 'COIN_GAINERS_REQUEST'
export const COIN_GAINERS_SUCCESS = 'COIN_GAINERS_SUCCESS'
export const COIN_GAINERS_FAIL = 'COIN_GAINERS_FAIL'

export const COIN_LOSERS_REQUEST = 'COIN_LOSERS_REQUEST'
export const COIN_LOSERS_SUCCESS = 'COIN_LOSERS_SUCCESS'
export const COIN_LOSERS_FAIL = 'COIN_LOSERS_FAIL'

export const COIN_TOTALS_REQUEST = 'COIN_TOTALS_REQUEST'
export const COIN_TOTALS_SUCCESS = 'COIN_TOTALS_SUCCESS'
export const COIN_TOTALS_FAIL = 'COIN_TOTALS_FAIL'
